.gallery {
    padding: 0 4rem;
    margin-bottom: 2rem;
    margin-top: 0 !important;
}

.gallery>div {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 2rem;
}

.gallery-container {
    width: 70%;
    display: flex;
    align-self: center !important;
    flex-direction: column;
    /* background-image: url("../images/pattern2.png");
    background-repeat: no-repeat, repeat; */
}

.gallery-container img {
    margin-bottom: 4rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
}

.slider-img {
    width: 25rem;
    height: 23rem;
}

@media only screen and (max-width: 500px) {
    .gallery {
        padding: 0 2rem;
    }
    .gallery>div {
        margin-left: unset;
    }
    .gallery-container img {
        margin-bottom: 2rem;
    }
    .slider-img {
        width: 25rem;
        height: 18rem;
    }
    .gallery-container {
        width: 90%;
    }
}

@media only screen and (max-width: 800px) {
    .gallery-container {
        width: 90%;
    }
}