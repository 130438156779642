.awards {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    /* background-color: #f7f7f9; */
    align-items: center;
    /* width: 100%; */
    /* height: auto; */
}

.awards > h1 {
    align-self: flex-start;
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 6rem;
    margin-left: 2rem;
}

.carousel-container {
    display: flex;
    width: 60vw;
    height: 50vh;
}