.research-container>h1 {
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 6rem;
}

.main-container {
    display: flex;
    width: 100%;
    /* padding-top: 6rem; */
    border-radius: 11px;
    margin: 2rem;
    margin-top: 0;
}

.left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 12px #dedede;
    width: 60%;
    border-radius: 11px;
    padding-bottom: 1rem;
    font-weight: 700;
    font-size: 13px;
    margin-left: 2rem;
}

.right {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
}

.left-side-content {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
}

.faculty-banner {
    display: flex;
    width: 100%;
    height: 260px;
    margin: 1rem;
    border-radius: 11px;
    background-color: #18202C;
}

.faculty-banner>img {
    border-radius: 11px;
}

.stats-card {
    width: 370px;
    height: 71px;
    background-color: rgba(232, 236, 241, 1);
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 11px;
}

.stats-num {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(103, 128, 159, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.stats-title {
    display: flex;
    flex-direction: column;
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 190px;
    flex-wrap: wrap;
    box-shadow: 5px 5px 12px #dedede;
    background-color: white;
    margin: 1rem;
    text-align: center;
    border-radius: 11px;
    font-weight: 700;
    font-size: 13px;
}

.highlight-value {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
}

.graph {
    height: 450px;
    padding: 2rem;
    margin-left: 2rem;
}

.facility-img {
    width: 80%;
    height: 80%;
    border-radius: 8px;
}

.section-facilities {
    display: flex;
    margin-left: 2rem;
}

.section-facilities > h2 {
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 2rem;
}
