@import url(https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700;900&display=swap);
.header-container {
  display: flex;
  width: 100%;
  height: 4.5rem;
  justify-content: space-between;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.2);
  position: fixed;
  background-color: #18202C;
  color: #fefcff;
  z-index: 3;
}

.header-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.header-sub-container img {
  width: 2rem;
  height: 3rem;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

#flag {
  width: 2.2rem;
  height: 2.2rem;
  margin-top: 0.5rem;
}

.header-sub-container ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.header-sub-container ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.nav-link {
  text-decoration: none !important;
  color: inherit !important;
}

.nav-link:hover {
  transition: 0.2s;
  border-bottom: 1px whitesmoke !important;
}

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}

.burger .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger .burger-lines:before {
  left: 1em;
  top: 1em;
}

.burger:after {
  content: "";
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}

.burger .burger-lines {
  top: 50%;
  margin-top: -0.125em;
}

.burger .burger-lines, .burger .burger-lines:after, .burger .burger-lines:before {
  pointer-events: none;
  display: block;
  content: "";
  width: 100%;
  border-radius: 0.25em;
  background-color: black;
  height: 0.25em;
  position: absolute;
  -webkit-transform: rotate(0);
  transform: rotate(0);
}

.burger .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger . :before {
  left: 1em;
  top: 1em;
}

.burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  width: 2em;
}

.burger.burger-slip .burger-lines, .burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  transition: all 0.2s;
}

.burger.burger-slip.open .burger-lines {
  -webkit-transform: translateX(-2em);
  transform: translateX(-2em);
  background-color: transparent;
}

.burger.burger-slip.open .burger-lines:before, .burger.burger-slip.open .burger-lines:after {
  left: 0.5em;
  top: 0px;
}

.burger.burger-slip.open .burger-lines:before {
  -webkit-transform: translateX(2em) rotate(135deg);
  transform: translateX(2em) rotate(135deg);
}

.burger.burger-slip.open .burger-lines:after {
  -webkit-transform: translateX(2em) rotate(-135deg);
  transform: translateX(2em) rotate(-135deg);
}

.mobile-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

@media only screen and (max-width: 600px) {
  .header-sub-container ul li {
    padding: 0.5rem;
    display: none;
  }
  .header-title {
    margin-left: 0.1rem;
    text-align: center;
    font-size: 1rem;
  }
  .header-sub-container img {
    width: 2.6rem;
    height: auto;
  }
  .header-sub-container {
    /* padding: 0rem 0.75rem; */
    margin-left: 0;
  }
  .header-sub-container img {
    width: 1.6rem;
    height: 2.2rem;
    margin: 0;
  }
  
  
  .header-container>.nav-link {
    display: flex;
  }
  #flag {
    display: none;
  }
}

.mobile-nav-links {
  position: fixed;
  top: -10rem;
  margin-top: 0.5em;
  min-width: 12em;
  width: 100%;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.2);
  padding: 0;
  border-radius: 0 0 0.2em 0.2em;
  transition: -webkit-transform 0.2s linear;
  transition: transform 0.2s linear;
  transition: transform 0.2s linear, -webkit-transform 0.2s linear;
  z-index: 5;
}

.mobile-nav-links>ul>li {
  background: white;
  font-size: 1.2rem;
  padding: 1rem;
  border-bottom: 1px solid #ededed;
}
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f9;
  padding-bottom: 3rem;
}

.home-container>h1 {
  font-weight: 700;
  font-size: 2.5rem;
  padding-top: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 0.8rem;
  margin-top: -0.5rem;
}

.home-container>h2 {
  font-weight: 500;
  font-size: 1.8rem;
  color: #747474;
  padding-top: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 0.8rem;
}

.logo {
  width: 9rem;
  height: 9rem;
  margin-top: 6rem;
}

.highlights {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: space-around;
}

.card-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: #ececec 0px 5px 19px;
  width: 15rem;
  height: 15rem;
  margin: 0rem 1rem;
  border-radius: 4px;
  padding: 1rem;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  transition: border-bottom-width 0.2s linear;
}

.card-item:hover {
  border-bottom-width: 6px;
}

.card-content {
  font-size: 3rem;
}

.card-title {
  color: #747474;
  font-size: 1.4rem;
}

.card-description {
  color: #949494;
  font-size: 0.85rem;
  line-height: 1.32;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 500px) {
  .home-container {
    height: unset;
    width: unset !important;
  }
  .home-container>h1 {
    font-size: 2.5rem;
    margin-left: unset;
  }
  .highlights {
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
  }
  .card-item {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .home-container {
    height: unset;
    width: unset !important;
  }
  .card-item {
    margin: 1rem 2rem;
  }
  .highlights {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
  }
}
.section {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-image: url(/static/media/memphis_pattern.d8a7d7cd.svg);
    background-repeat: no-repeat, repeat;
    padding-bottom: 2rem;
}

.section>p {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 5rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.about-us-body {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about-us-text {
    display: flex;
    flex-direction: column;
    text-align: justify;
    align-items: center;
    width: 60%;
    line-height: 2rem;
    font-weight: 500;
    margin-left: 5rem;
    font-size: 1.2rem;
}

.about-us-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 1.5rem;
    padding-top: 0;
}

.about-us-image>img {
    width: 22rem;
    height: 20rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
}

.about-us-image>p {
    margin-top: 0.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 500px) {
    .section > p{
        margin-left: 6rem;
    }
    .about-us-body{
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        padding-top: 0;
        margin-top: 2rem;
    }
    .about-us-text{
        margin-left: 0;
        width: unset;
    }
    .about-us-image{
        width: unset;
        margin-top: 2rem;
    }
    .section{
        padding-bottom: unset !important;
    }
}
.research {
    padding: 0 4rem;
    align-items: flex-start;
    border-top: 1px solid #ededed;
    margin-top: unset !important;
}

.research>div {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 2rem;
}

.research>p {
    font-size: 1.2rem;
    color: #747474;
    font-weight: 500;
    align-self: flex-start;
    margin-left: 2rem;
    margin-top: 2rem;
}

a:link {
    text-decoration: none;
}

.research-areas {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.research-item {
    display: flex;
    flex-direction: column;
    margin-right: 3.5rem;
    /* width: 33%; */
}

.research-item>img {
    width: 50px;
    height: 50px;
}

.research-item>p {
    color: #747474;
    margin-top: 1rem;
    padding-right: 2rem;
    font-weight: 500;
    font-size: 1rem;
}

.research-item>div {
    margin-top: 1.5rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.collab-logo {
    width: 120px;
    height: 100px;
}

.publication-link {
    color: #747474;
    align-self: flex-start;
    border-bottom: 1.2px solid #747474;
    margin-left: 2rem;
    margin-top: 1.5rem;
    font-size: 1.1rem;
}

@media only screen and (max-width: 500px) {
    .research {
        padding: 0 2rem;
        align-items: center;
    }
    .research-areas {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;
        width: unset;
        align-items: center;
    }
    .research>p {
        text-align: center;
        margin-left: unset;
    }
    .research>div {
        margin-left: unset;
        text-align: center;
    }
    .research-item {
        width: unset;
    }
    .section-heading {
        margin-left: 2rem !important;
    }
    .publication-link{
        margin: 0;
        margin-bottom: 2rem;
    }
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
}

html {
  scroll-behavior: smooth;
}

.section {
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  background-image: url(/static/media/memphis_pattern.d8a7d7cd.svg);
  background-repeat: no-repeat, repeat;
  padding-bottom: 4rem;
}

button {
  outline: none !important;
}

.gallery {
    padding: 0 4rem;
    margin-bottom: 2rem;
    margin-top: 0 !important;
}

.gallery>div {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 2rem;
}

.gallery-container {
    width: 70%;
    display: flex;
    align-self: center !important;
    flex-direction: column;
    /* background-image: url("../images/pattern2.png");
    background-repeat: no-repeat, repeat; */
}

.gallery-container img {
    margin-bottom: 4rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
}

.slider-img {
    width: 25rem;
    height: 23rem;
}

@media only screen and (max-width: 500px) {
    .gallery {
        padding: 0 2rem;
    }
    .gallery>div {
        margin-left: unset;
    }
    .gallery-container img {
        margin-bottom: 2rem;
    }
    .slider-img {
        width: 25rem;
        height: 18rem;
    }
    .gallery-container {
        width: 90%;
    }
}

@media only screen and (max-width: 800px) {
    .gallery-container {
        width: 90%;
    }
}
.footer {
    background-color: whitesmoke;
    margin-top: unset !important;
}

.footer>p {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-left: 4rem;
    margin-top: 1.5rem;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    align-self: flex-start;
    margin-left: 4rem;
    width: 90% !important;
}

.address {
    display: flex;
    flex-direction: column;
    width: 40%;
    line-height: 2rem;
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 3rem;
}

.map {
    display: flex;
    flex-direction: column;
}

.map>iframe {
    width: 600px;
    height: 300px;
    border: none;
    margin-left: 7rem;
}

@media only screen and (max-width: 500px) {
    .footer-container {
        flex-direction: column;
        width: 100% !important;
    }
    .address {
        width: unset;
    }
    .map {
        display: none;
    }
    .footer>p {
        margin-left: 6rem;
    }
    .address {
        padding-bottom: 2rem;
    }
}

@media only screen and (max-width: 800px) {
    .footer-container {
        width: 100%;
        align-items: center;
        margin-left: unset;
    }
    .address {
        flex-direction: column;
    }
    .address>p {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .map>iframe {
        width: 350px;
        margin-left: unset;
    }
}
.media {
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f9;
  align-items: center !important;
  
}

.media > h1 {
  margin-left: 2rem;
  font-weight: 700;
  padding: 1rem;
  font-size: 3rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.media > h2 {
  color: #747474;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
}

.media-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  padding-bottom: 0;
}

.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 2rem;
  box-shadow: #efefef 0px 5px 12px;
}

.gallery-item :last-child {
  border-bottom: none;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  width: 100%;
  border-top: 1px solid #ededed;
  z-index: 2;
}

.title-container > h3 {
  font-weight: 200;
  font-size: 1.6rem;
  padding: 2rem;
}

.card-image {
  width: 100%;
  height: 25rem;
  z-index: 1;
}

@media only screen and (max-width: 500px) {
  .card-image {
    height: 13rem;
  }
}

@media only screen and (max-width: 800px) {
  .gallery-item {
    width: 90%;
  }

  .title-container > h3 {
    font-weight: 200;
    font-size: 1.4rem;
    padding: 0.6rem;
  }
}

.publications {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.publications>h1 {
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    margin-top: 5rem;
}

.table-container {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.pdf-icon {
    width: 35px;
    height: 35px;
}

.pub-link {
    text-decoration: none !important;
    color: inherit !important;
    transition: 0.2s;
    padding-bottom: 1rem;
    
}

.pub-link:hover {
    border-bottom: 1px solid #747474 !important;
}
.section-research {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    padding: 8rem 2rem 2rem 2rem;
}

.research-heading {
    font-size: 1.6rem;
    font-weight: 700;
    padding-left: 2rem;
}

.intro {
    font-size: 1.4rem;
    padding: 2rem;
    /* font-weight: 700; */
    color: #949494;
    line-height: 2rem;
}

.section-item {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 1.3rem;
    border-top: 1px solid #ededed;
}

.section-child-item > p {
    line-height: 2rem;
    font-weight: 500;
    font-size: 1.2rem;
}
.section-child-item > h2{
    margin-top: 1.5rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.section-item > img {
    width: 450px;
    height: 310px;
}

.section-child-item {
    display: flex;
    flex-direction: column;
    width: 40%;
    border-top: 2px solid #18202C;
    /* margin: 1rem; */
}
.awards {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    /* background-color: #f7f7f9; */
    align-items: center;
    /* width: 100%; */
    /* height: auto; */
}

.awards > h1 {
    align-self: flex-start;
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 6rem;
    margin-left: 2rem;
}

.carousel-container {
    display: flex;
    width: 60vw;
    height: 50vh;
}
.research-container>h1 {
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 6rem;
}

.main-container {
    display: flex;
    width: 100%;
    /* padding-top: 6rem; */
    border-radius: 11px;
    margin: 2rem;
    margin-top: 0;
}

.left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 5px 5px 12px #dedede;
    width: 60%;
    border-radius: 11px;
    padding-bottom: 1rem;
    font-weight: 700;
    font-size: 13px;
    margin-left: 2rem;
}

.right {
    width: 35%;
    display: flex;
    flex-wrap: wrap;
}

.left-side-content {
    display: flex;
    flex-wrap: wrap;
    width: 90%;
    justify-content: space-between;
}

.faculty-banner {
    display: flex;
    width: 100%;
    height: 260px;
    margin: 1rem;
    border-radius: 11px;
    background-color: #18202C;
}

.faculty-banner>img {
    border-radius: 11px;
}

.stats-card {
    width: 370px;
    height: 71px;
    background-color: rgba(232, 236, 241, 1);
    display: flex;
    align-items: center;
    padding: 1rem;
    margin: 0.5rem;
    border-radius: 11px;
}

.stats-num {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 3px solid rgba(103, 128, 159, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
}

.stats-title {
    display: flex;
    flex-direction: column;
}

.card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 190px;
    flex-wrap: wrap;
    box-shadow: 5px 5px 12px #dedede;
    background-color: white;
    margin: 1rem;
    text-align: center;
    border-radius: 11px;
    font-weight: 700;
    font-size: 13px;
}

.highlight-value {
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 11px;
}

.graph {
    height: 450px;
    padding: 2rem;
    margin-left: 2rem;
}

.facility-img {
    width: 80%;
    height: 80%;
    border-radius: 8px;
}

.section-facilities {
    display: flex;
    margin-left: 2rem;
}

.section-facilities > h2 {
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    padding-top: 2rem;
}

.projects {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f9;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.projects > h1 {
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    margin-top: 5rem;
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
}

.table-container {
    display: flex;
    width: 80%;
    justify-content: center;
}
.students {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f9;
    align-items: center;
}

.students>h1 {
    margin-left: 3rem;
    font-weight: 700;
    font-size: 2rem;
    padding-top: 6rem;
    align-self: flex-start;
}

.student-container {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    justify-content: center;
    align-items: center;
}

.member-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 3px 3px 21px 0 rgb(0 0 0 / 9%);
    width: 220px;
    margin: 1rem;
}

.member-details {
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 185px;
    padding: 0.7rem;
    text-align: center;
}


.member-img {
    width: 220px;
    height: 220px;
}

.member-name {
    font-weight: 700;
}

.description {
color: #747474;
}
.active-students {
    padding: 0 4rem;
    margin-bottom: 2rem;
    margin-top: 0 !important;
    margin-left: 3rem;
}
.active-students > h1 {
    font-weight: 700;
    font-size: 2rem;
    padding-top: 7rem;
    align-self: flex-start;
}

.container-main {
    padding: 2rem 0;
    /* border-top: 1px solid #ededed */
}




#project-title {
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 1.7rem 0 0.8rem 0;
    color: #747474;
    /* border-bottom: 1px solid #; */
    /* box-shadow: 0px 24px 3px -24px #18202C; */
}

.active-students-img {
    width: 12rem;
    height: 12rem;
}

.member-container {
    display: flex;
    border-radius: 5px;
    box-shadow: 3px 3px 21px 0 rgb(0 0 0 / 9%);
    width: 220px;
    margin: 1rem;
}

.member-details {
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    height: auto;
    padding: 0.7rem;
    text-align: center;
}

.project-container {
    display: flex;
}


.member-img {
    width: 220px;
    height: 220px;
}

.member-name {
    font-weight: 700;
}




#award {
padding: 0;
margin: 1rem;
margin-top: 0.2rem;
font-size: 1rem;
color: #747474;
}

.description {
    color: #747474;
    }


