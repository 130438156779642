.footer {
    background-color: whitesmoke;
    margin-top: unset !important;
}

.footer>p {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-left: 4rem;
    margin-top: 1.5rem;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    align-self: flex-start;
    margin-left: 4rem;
    width: 90% !important;
}

.address {
    display: flex;
    flex-direction: column;
    width: 40%;
    line-height: 2rem;
    font-weight: 500;
    font-size: 1.2rem;
    margin-top: 3rem;
}

.map {
    display: flex;
    flex-direction: column;
}

.map>iframe {
    width: 600px;
    height: 300px;
    border: none;
    margin-left: 7rem;
}

@media only screen and (max-width: 500px) {
    .footer-container {
        flex-direction: column;
        width: 100% !important;
    }
    .address {
        width: unset;
    }
    .map {
        display: none;
    }
    .footer>p {
        margin-left: 6rem;
    }
    .address {
        padding-bottom: 2rem;
    }
}

@media only screen and (max-width: 800px) {
    .footer-container {
        width: 100%;
        align-items: center;
        margin-left: unset;
    }
    .address {
        flex-direction: column;
    }
    .address>p {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .map>iframe {
        width: 350px;
        margin-left: unset;
    }
}