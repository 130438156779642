.media {
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  background-color: #f7f7f9;
  align-items: center !important;
  
}

.media > h1 {
  margin-left: 2rem;
  font-weight: 700;
  padding: 1rem;
  font-size: 3rem;
  margin-top: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.media > h2 {
  color: #747474;
  font-weight: 500;
  font-size: 1.4rem;
  text-align: center;
}

.media-gallery {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 2rem;
  justify-content: center;
  padding-bottom: 0;
}

.gallery-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  margin: 2rem;
  box-shadow: #efefef 0px 5px 12px;
}

.gallery-item :last-child {
  border-bottom: none;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  background-color: white;
  width: 100%;
  border-top: 1px solid #ededed;
  z-index: 2;
}

.title-container > h3 {
  font-weight: 200;
  font-size: 1.6rem;
  padding: 2rem;
}

.card-image {
  width: 100%;
  height: 25rem;
  z-index: 1;
}

@media only screen and (max-width: 500px) {
  .card-image {
    height: 13rem;
  }
}

@media only screen and (max-width: 800px) {
  .gallery-item {
    width: 90%;
  }

  .title-container > h3 {
    font-weight: 200;
    font-size: 1.4rem;
    padding: 0.6rem;
  }
}
