@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;600;700;900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Nunito", sans-serif;
  font-size: 1rem;
}

html {
  scroll-behavior: smooth;
}

.section {
  border-top: 1px solid #ededed;
  display: flex;
  flex-direction: column;
  background-image: url("./images/memphis_pattern.svg");
  background-repeat: no-repeat, repeat;
  padding-bottom: 4rem;
}

button {
  outline: none !important;
}
