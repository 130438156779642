.publications {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.publications>h1 {
    margin-left: 2rem;
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    margin-top: 5rem;
}

.table-container {
    display: flex;
    width: 80%;
    justify-content: center;
    align-items: center;
}

.pdf-icon {
    width: 35px;
    height: 35px;
}

.pub-link {
    text-decoration: none !important;
    color: inherit !important;
    transition: 0.2s;
    padding-bottom: 1rem;
    
}

.pub-link:hover {
    border-bottom: 1px solid #747474 !important;
}