.students {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f9;
    align-items: center;
}

.students>h1 {
    margin-left: 3rem;
    font-weight: 700;
    font-size: 2rem;
    padding-top: 6rem;
    align-self: flex-start;
}

.student-container {
    display: flex;
    flex-wrap: wrap;
    width: 90vw;
    justify-content: center;
    align-items: center;
}

.member-container {
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 3px 3px 21px 0 rgb(0 0 0 / 9%);
    width: 220px;
    margin: 1rem;
}

.member-details {
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    height: 185px;
    padding: 0.7rem;
    text-align: center;
}


.member-img {
    width: 220px;
    height: 220px;
}

.member-name {
    font-weight: 700;
}

.description {
color: #747474;
}