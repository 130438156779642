.section-research {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    padding: 8rem 2rem 2rem 2rem;
}

.research-heading {
    font-size: 1.6rem;
    font-weight: 700;
    padding-left: 2rem;
}

.intro {
    font-size: 1.4rem;
    padding: 2rem;
    /* font-weight: 700; */
    color: #949494;
    line-height: 2rem;
}

.section-item {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 1.3rem;
    border-top: 1px solid #ededed;
}

.section-child-item > p {
    line-height: 2rem;
    font-weight: 500;
    font-size: 1.2rem;
}
.section-child-item > h2{
    margin-top: 1.5rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.section-item > img {
    width: 450px;
    height: 310px;
}

.section-child-item {
    display: flex;
    flex-direction: column;
    width: 40%;
    border-top: 2px solid #18202C;
    /* margin: 1rem; */
}