.header-container {
  display: flex;
  width: 100%;
  height: 4.5rem;
  justify-content: space-between;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.2);
  position: fixed;
  background-color: #18202C;
  color: #fefcff;
  z-index: 3;
}

.header-sub-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

.header-sub-container img {
  width: 2rem;
  height: 3rem;
  margin-left: 0.5rem;
  margin-top: 0.2rem;
}

#flag {
  width: 2.2rem;
  height: 2.2rem;
  margin-top: 0.5rem;
}

.header-sub-container ul {
  list-style: none;
  display: flex;
  margin-bottom: 0;
}

.header-sub-container ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.nav-link {
  text-decoration: none !important;
  color: inherit !important;
}

.nav-link:hover {
  transition: 0.2s;
  border-bottom: 1px whitesmoke !important;
}

.burger {
  height: 3em;
  width: 3em;
  position: relative;
  font-size: 12px;
  cursor: pointer;
  -webkit-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  -webkit-tap-highlight-color: transparent;
}

.burger .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger .burger-lines:before {
  left: 1em;
  top: 1em;
}

.burger:after {
  content: "";
  display: block;
  position: absolute;
  height: 150%;
  width: 150%;
  top: -25%;
  left: -25%;
}

.burger .burger-lines {
  top: 50%;
  margin-top: -0.125em;
}

.burger .burger-lines, .burger .burger-lines:after, .burger .burger-lines:before {
  pointer-events: none;
  display: block;
  content: "";
  width: 100%;
  border-radius: 0.25em;
  background-color: black;
  height: 0.25em;
  position: absolute;
  -webkit-transform: rotate(0);
  -ms-transform: rotate(0);
  transform: rotate(0);
}

.burger .burger-lines:after {
  left: 0;
  top: -1em;
}

.burger . :before {
  left: 1em;
  top: 1em;
}

.burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  width: 2em;
}

.burger.burger-slip .burger-lines, .burger.burger-slip .burger-lines:after, .burger.burger-slip .burger-lines:before {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

.burger.burger-slip.open .burger-lines {
  -webkit-transform: translateX(-2em);
  -ms-transform: translateX(-2em);
  transform: translateX(-2em);
  background-color: transparent;
}

.burger.burger-slip.open .burger-lines:before, .burger.burger-slip.open .burger-lines:after {
  left: 0.5em;
  top: 0px;
}

.burger.burger-slip.open .burger-lines:before {
  -webkit-transform: translateX(2em) rotate(135deg);
  -ms-transform: translateX(2em) rotate(135deg);
  transform: translateX(2em) rotate(135deg);
}

.burger.burger-slip.open .burger-lines:after {
  -webkit-transform: translateX(2em) rotate(-135deg);
  -ms-transform: translateX(2em) rotate(-135deg);
  transform: translateX(2em) rotate(-135deg);
}

.mobile-nav {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

@media only screen and (max-width: 600px) {
  .header-sub-container ul li {
    padding: 0.5rem;
    display: none;
  }
  .header-title {
    margin-left: 0.1rem;
    text-align: center;
    font-size: 1rem;
  }
  .header-sub-container img {
    width: 2.6rem;
    height: auto;
  }
  .header-sub-container {
    /* padding: 0rem 0.75rem; */
    margin-left: 0;
  }
  .header-sub-container img {
    width: 1.6rem;
    height: 2.2rem;
    margin: 0;
  }
  
  
  .header-container>.nav-link {
    display: flex;
  }
  #flag {
    display: none;
  }
}

.mobile-nav-links {
  position: fixed;
  top: -10rem;
  margin-top: 0.5em;
  min-width: 12em;
  width: 100%;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.2);
  padding: 0;
  border-radius: 0 0 0.2em 0.2em;
  transition: transform 0.2s linear;
  z-index: 5;
}

.mobile-nav-links>ul>li {
  background: white;
  font-size: 1.2rem;
  padding: 1rem;
  border-bottom: 1px solid #ededed;
}