.projects {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    background-color: #f7f7f9;
    align-items: center;
    width: 100%;
    height: 100vh;
}

.projects > h1 {
    font-weight: 700;
    padding: 1rem;
    font-size: 2rem;
    margin-top: 5rem;
    /* display: flex;
    align-items: center;
    justify-content: center;
    text-align: center; */
}

.table-container {
    display: flex;
    width: 80%;
    justify-content: center;
}