.section {
    border-top: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    background-image: url("../images/memphis_pattern.svg");
    background-repeat: no-repeat, repeat;
    padding-bottom: 2rem;
}

.section>p {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 5rem;
    display: flex;
    align-items: center;
    text-align: center;
}

.about-us-body {
    margin-top: 4rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.about-us-text {
    display: flex;
    flex-direction: column;
    text-align: justify;
    align-items: center;
    width: 60%;
    line-height: 2rem;
    font-weight: 500;
    margin-left: 5rem;
    font-size: 1.2rem;
}

.about-us-image {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 1.5rem;
    padding-top: 0;
}

.about-us-image>img {
    width: 22rem;
    height: 20rem;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 4px 16px;
}

.about-us-image>p {
    margin-top: 0.5rem;
    font-weight: 500;
}

@media only screen and (max-width: 500px) {
    .section > p{
        margin-left: 6rem;
    }
    .about-us-body{
        flex-direction: column;
        justify-content: center;
        padding: 2rem;
        padding-top: 0;
        margin-top: 2rem;
    }
    .about-us-text{
        margin-left: 0;
        width: unset;
    }
    .about-us-image{
        width: unset;
        margin-top: 2rem;
    }
    .section{
        padding-bottom: unset !important;
    }
}