.research {
    padding: 0 4rem;
    align-items: flex-start;
    border-top: 1px solid #ededed;
    margin-top: unset !important;
}

.research>div {
    font-size: 2rem;
    font-weight: 700;
    align-self: flex-start;
    margin-top: 3rem;
    margin-left: 2rem;
}

.research>p {
    font-size: 1.2rem;
    color: #747474;
    font-weight: 500;
    align-self: flex-start;
    margin-left: 2rem;
    margin-top: 2rem;
}

a:link {
    text-decoration: none;
}

.research-areas {
    display: flex;
    justify-content: space-between;
    width: 90%;
}

.research-item {
    display: flex;
    flex-direction: column;
    margin-right: 3.5rem;
    /* width: 33%; */
}

.research-item>img {
    width: 50px;
    height: 50px;
}

.research-item>p {
    color: #747474;
    margin-top: 1rem;
    padding-right: 2rem;
    font-weight: 500;
    font-size: 1rem;
}

.research-item>div {
    margin-top: 1.5rem;
    font-weight: 700;
    font-size: 1.2rem;
}

.collab-logo {
    width: 120px;
    height: 100px;
}

.publication-link {
    color: #747474;
    align-self: flex-start;
    border-bottom: 1.2px solid #747474;
    margin-left: 2rem;
    margin-top: 1.5rem;
    font-size: 1.1rem;
}

@media only screen and (max-width: 500px) {
    .research {
        padding: 0 2rem;
        align-items: center;
    }
    .research-areas {
        flex-direction: column;
        justify-content: center;
        padding-bottom: 2rem;
        width: unset;
        align-items: center;
    }
    .research>p {
        text-align: center;
        margin-left: unset;
    }
    .research>div {
        margin-left: unset;
        text-align: center;
    }
    .research-item {
        width: unset;
    }
    .section-heading {
        margin-left: 2rem !important;
    }
    .publication-link{
        margin: 0;
        margin-bottom: 2rem;
    }
}