.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f7f7f9;
  padding-bottom: 3rem;
}

.home-container>h1 {
  font-weight: 700;
  font-size: 2.5rem;
  padding-top: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 0.8rem;
  margin-top: -0.5rem;
}

.home-container>h2 {
  font-weight: 500;
  font-size: 1.8rem;
  color: #747474;
  padding-top: 0 2rem;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  text-align: center;
  margin: 0.8rem;
}

.logo {
  width: 9rem;
  height: 9rem;
  margin-top: 6rem;
}

.highlights {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: space-around;
}

.card-item {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  box-shadow: #ececec 0px 5px 19px;
  width: 15rem;
  height: 15rem;
  margin: 0rem 1rem;
  border-radius: 4px;
  padding: 1rem;
  border-bottom-style: solid;
  border-bottom-width: 3px;
  transition: border-bottom-width 0.2s linear;
}

.card-item:hover {
  border-bottom-width: 6px;
}

.card-content {
  font-size: 3rem;
}

.card-title {
  color: #747474;
  font-size: 1.4rem;
}

.card-description {
  color: #949494;
  font-size: 0.85rem;
  line-height: 1.32;
  margin-top: 0.5rem;
}

@media only screen and (max-width: 500px) {
  .home-container {
    height: unset;
    width: unset !important;
  }
  .home-container>h1 {
    font-size: 2.5rem;
    margin-left: unset;
  }
  .highlights {
    flex-direction: column;
    justify-content: center;
    margin-top: 2rem;
  }
  .card-item {
    margin-bottom: 2rem;
  }
}

@media only screen and (max-width: 800px) {
  .home-container {
    height: unset;
    width: unset !important;
  }
  .card-item {
    margin: 1rem 2rem;
  }
  .highlights {
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 2rem;
  }
}