.active-students {
    padding: 0 4rem;
    margin-bottom: 2rem;
    margin-top: 0 !important;
    margin-left: 3rem;
}
.active-students > h1 {
    font-weight: 700;
    font-size: 2rem;
    padding-top: 7rem;
    align-self: flex-start;
}

.container-main {
    padding: 2rem 0;
    /* border-top: 1px solid #ededed */
}




#project-title {
    font-size: 1.3rem;
    font-weight: 600;
    padding-top: 1.7rem 0 0.8rem 0;
    color: #747474;
    /* border-bottom: 1px solid #; */
    /* box-shadow: 0px 24px 3px -24px #18202C; */
}

.active-students-img {
    width: 12rem;
    height: 12rem;
}

.member-container {
    display: flex;
    border-radius: 5px;
    box-shadow: 3px 3px 21px 0 rgb(0 0 0 / 9%);
    width: 220px;
    margin: 1rem;
}

.member-details {
    background-color: white;
    align-items: center;
    justify-content: center;
    display: flex;
    height: auto;
    padding: 0.7rem;
    text-align: center;
}

.project-container {
    display: flex;
}


.member-img {
    width: 220px;
    height: 220px;
}

.member-name {
    font-weight: 700;
}




#award {
padding: 0;
margin: 1rem;
margin-top: 0.2rem;
font-size: 1rem;
color: #747474;
}

.description {
    color: #747474;
    }

